import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../../components/layout'
import { Indications, Caption, BenefitsStatic, PackageImage, ContraIndications} from '../../components/section_part'
import SEO from '../../components/seo'


export default class ProductDetail extends React.Component {
  render (){
    const product = get(this.props.data,"markdownRemark")
    return (
      <Layout>
        <SEO title={product.frontmatter.name.toUpperCase()+" "+product.frontmatter.ingredients} description={product.frontmatter.abstract}/>
        <section className ={"section section--full section--"+product.frontmatter.category+" section--"+product.frontmatter.type+"s"} data-nid="75">
          <Caption data = {product.frontmatter} />
          <div className = "container">
            <div className="section__content">
              <div className = "row">
                <div className = "col-xl-7 mr-auto my-4">
                  <div className = "section__part">
                    <h3 className = {"h2 text-"+product.frontmatter.category+" text-center text-sm-left mb-2"}>{product.frontmatter.ingredients}</h3>
                    <p className  = "perex">{product.frontmatter.abstract}</p>
                    <div className="d-block d-xl-none mx-8 my-4">
                      <PackageImage data = {product.frontmatter} />
                      <div className = "text-center">
                        <strong className = "text-uppercase">Paketler</strong><br/>{product.frontmatter.packages}
                      </div>
                    </div>
                    
                  </div>
                    <Indications data={product.frontmatter} />
                    { product.frontmatter.contraindications && <ContraIndications data={product.frontmatter}/>}
                    <BenefitsStatic data={product.frontmatter} />
                    <div className = "section__part" dangerouslySetInnerHTML={{__html:product.html}}>
                    
                    </div>
                
                  </div>
               
                  <div className="col-xl-4 ml-auto my-4">
                  <div className="d-none d-xl-block mb-8 text-center">
                    <PackageImage data = {product.frontmatter} />
                    <div className = "text-center">
                    <strong className = "text-uppercase">Paketler</strong><br/>{product.frontmatter.packages}
                  </div>
                  </div>
                  
                  
                </div>
               
               
                  </div>
                
            
            </div>
          
          </div>
        </section>
      </Layout>

    )

  }

}

export const query = graphql`
query ProductDetailQuery ($productId: String!) {
    markdownRemark(id: {eq: $productId}) {
      id
      html
      frontmatter {
        title
        name
        ingredients
        type
        category
        abstract
        packages
        image
        benefits {
          icon
          iconTitle
          desc
        }
        contraindications
        indications
      }
    }
  }

`